<template>
  <div class="submit">
    <slot name="action"></slot>
</div>
</template>

<script>
export default {

}
</script>

<style>

</style>